.cardForm {
  width: 100%;
  max-width: 450px;
  margin: 50px 0px;
}

.cardForm label{
  font-size: 12px;
}

.form-input{
  margin: 10px 0px;
}

.progress-container{
  height: 50px;
  width: 100%;
  display: flex;
  margin: 30px 0px;
}

.card-form-submit{
  margin-top: 20px;
}